import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  Switch,
  Radio,
  RadioGroup,
  FormLabel
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import StatusBox from "../../../component/StatusBox";
import { setJobEnsemble, setJobMethods, setParaDict, setEditPara } from "../../../store/reducers/job";
import AlterWindow from "../../../component/AlertWindow";

export const MethodBlock = ({ setStep }) => {
  const dispatch = useDispatch();
  const methods = useSelector((state) => state.job.jobMethods) || [];
  const [selectedModels, setSelectedModels] = useState(methods);
  const [editParams, setEditParams] = useState(useSelector((state) => state.job.editPara));
  const [ensembleparam, setEnsembleparam] = useState(
    useSelector((state) => state.job.jobEnsemble)
  );
  const paraDict = useSelector((state) => state.job.paraDict) || {};
  const [tempParaDict, setTempParaDict] = useState(paraDict);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (selectedModels.includes(value)) {
      setSelectedModels(selectedModels.filter((model) => model !== value));
    } else {
      setSelectedModels([...selectedModels, value]);
    }
  };

  const [alertOpen, setAlertOpen] = useState(false);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleSubmit = () => {
    if (selectedModels.length === 0) {
      setAlertOpen(true);
      return
    }
    if (selectedModels.includes("PRSCS") && selectedModels.length === 1) {
      setAlertOpen(true);
      return
    }
    dispatch(setParaDict(tempParaDict));
    dispatch(setEditPara(editParams));
    dispatch(setJobMethods(selectedModels));
    dispatch(setJobEnsemble(ensembleparam));
    setStep(4);
  };

  const handleBack = () => {
    dispatch(setJobMethods(selectedModels));
    dispatch(setJobEnsemble(ensembleparam));
    dispatch(setParaDict(tempParaDict));
    dispatch(setEditPara(editParams));
    setStep(2);
  };


  const gridItemStyle = {
    paddingBottom: 2,
    paddingLeft: 2,
    paddingRight: 2,
    border: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: 5,
        mb: 5,
      }}
    >
      <Box
        sx={{
          width: "95%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #ddd",
          borderRadius: "5px",
          pt: 2,
          pl: 2,
          overflow: "auto",
        }}
      >
        <AlterWindow
          open={alertOpen}
          onClose={handleAlertClose}
          title="⚠️ Warning"
          message={"Please select at least one method."}
        />
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 12, sm: 6, md: 12 }}
        >
          {/* Method Selection */}
          <Grid item xs={3} sx={gridItemStyle}>
            <Typography variant="h6">
              Methods:
            </Typography>
            <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
              {!selectedModels.includes("PRSCS") && (
                <Typography variant="body2">
                  Please select at least one method.
                </Typography>)
              }
              {!selectedModels.includes("PRSCS") && (

                <Typography variant="body2">
                  <br></br> Note:  If multiple methods are selected, we provide an option to train an ensemble PRS based on the selected methods.
                </Typography>)
              }
              {selectedModels.includes("PRSCS") && (
                <Typography variant="body2">
                   Please select at least one method. <br></br> If you want to run pesudo training PRS methods, please go back and select the other option.
                </Typography>)
              }
            </Box>
          </Grid>
          <Grid item xs={5} sx={gridItemStyle}>
            {!selectedModels.includes("PRSCS") && (
              <FormControl component="fieldset" sx={{ width: "50%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedModels.includes("Lassosum2")}
                          onChange={handleCheckboxChange}
                          value="Lassosum2"
                        />
                      }
                      label="Lassosum2"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedModels.includes("LDpred2")}
                          onChange={handleCheckboxChange}
                          value="LDpred2"
                        />
                      }
                      label="LDpred2"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedModels.includes("C+T")}
                          onChange={handleCheckboxChange}
                          value="C+T"
                        />
                      }
                      label="C+T"
                    />
                  </Grid>
                  {selectedModels.length > 1 && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={ensembleparam}
                            onChange={() => setEnsembleparam(!ensembleparam)}
                            color="primary"
                          />
                        }
                        label="Run Ensemble?"
                      />
                    </Grid>
                  )}
                </Grid>
              </FormControl>)}

            {selectedModels.includes("PRSCS") && (
              <FormControl component="fieldset" sx={{ width: "50%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedModels.includes("PRS-CS")}
                          onChange={handleCheckboxChange}
                          value="PRS-CS"
                        />
                      }
                      label="PRS-CS"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedModels.includes("LDPred2-auto")}
                          onChange={handleCheckboxChange}
                          value="LDPred2-auto"
                        />
                      }
                      label="LDPred2-auto"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedModels.includes("DBSLMM")}
                          onChange={handleCheckboxChange}
                          value="DBSLMM"
                        />
                      }
                      label="DBSLMM"
                    />
                  </Grid>
                </Grid>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4} sx={gridItemStyle}>
            <StatusBox
              message="Please select one or more methods."
              status={selectedModels.length === 0 ? "required" : selectedModels.length === 1 && selectedModels.includes("PRSCS") ? "required" : "ok"}
            />
          </Grid>

          {/* Parameters */}
          {!selectedModels.includes("PRSCS") && (
            <Grid item xs={3} sx={gridItemStyle}>
              <Typography variant="h6" >
                Parameters:
              </Typography>
              <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                <Typography variant="body2">
                  We allow customized parameter settings, however, we STRONGLY recommend using the default settings.
                </Typography>
              </Box>
            </Grid>
          )}
          {!selectedModels.includes("PRSCS") && (
            <Grid item xs={5} sx={gridItemStyle}>

              <FormControl
                component="fieldset"
                sx={{
                  width: "60%",
                  mb: 3,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center", // Centers the content horizontally
                }}
              >
                {/* Container for the labels and switch */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* "Default" Label */}
                  <FormLabel
                    component="legend"
                    sx={{ marginRight: 1, whiteSpace: 'nowrap', color: "black" }} // Prevents label from wrapping
                  >
                    Default
                  </FormLabel>

                  {/* Switch Component */}
                  <Switch
                    checked={editParams}
                    onChange={() => setEditParams(!editParams)}
                    color="primary"
                  />

                  {/* "Customized" Label */}
                  <FormLabel
                    component="legend"
                    sx={{ marginLeft: 1, whiteSpace: 'nowrap', color: "black" }} // Prevents label from wrapping
                  >
                    Customized
                  </FormLabel>
                </Box>
              </FormControl>

              {editParams && (
                <Box
                  sx={{ width: "90%", display: "flex", flexDirection: "column" }}
                >
                  {selectedModels.includes("C+T") && (
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      C+T
                    </Typography>
                  )}
                  {selectedModels.includes("C+T") && (
                    <TextField
                      variant="outlined"
                      label="kb"
                      fullWidth
                      defaultValue={tempParaDict["kb"] || "500"}
                      onChange={(e) => setTempParaDict({ ...tempParaDict, kb: e.target.value })}
                      error={tempParaDict["kb"] && isNaN(tempParaDict["kb"])}
                      helperText={tempParaDict["kb"] && isNaN(tempParaDict["kb"]) ? "Please enter a number." : ""}
                      sx={{ mb: 2 }}
                    />
                  )}
                  {selectedModels.includes("C+T") && (
                    <TextField
                      variant="outlined"
                      label="P-value threshold"
                      fullWidth
                      defaultValue={tempParaDict["pval_threshold"] || "5e-08,5e-07,5e-06,5e-05,0.0005,0.005,0.05,0.5"}
                      onChange={(e) => setTempParaDict({ ...tempParaDict, pval_threshold: e.target.value })}
                      error={tempParaDict["pval_threshold"] && tempParaDict["pval_threshold"].split(",").some((x) => isNaN(x))}
                      helperText={tempParaDict["pval_threshold"] && tempParaDict["pval_threshold"].split(",").some((x) => isNaN(x)) ? "Please enter a comma-separated list of numbers." : ""}
                      sx={{ mb: 2 }}
                    />
                  )}
                  {selectedModels.includes("C+T") && (
                    <TextField
                      variant="outlined"
                      label="R2"
                      fullWidth
                      value={tempParaDict["r2"] || "0.1"}
                      onChange={(e) => setTempParaDict({ ...tempParaDict, r2: e.target.value })}
                      error={tempParaDict["r2"] && isNaN(tempParaDict["r2"])}
                      helperText={tempParaDict["r2"] && isNaN(tempParaDict["r2"]) ? "Please enter a number." : ""}
                      sx={{ mb: 2 }}
                    />
                  )}

                  {selectedModels.includes("Lassosum2") && (
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Lassosum2
                    </Typography>
                  )}
                  {selectedModels.includes("Lassosum2") && (
                    <TextField
                      variant="outlined"
                      label="delta"
                      fullWidth
                      defaultValue={tempParaDict["delta"] || "0.001,0.01,0.1,1.0"}
                      onChange={(e) => setTempParaDict({ ...tempParaDict, delta: e.target.value })}
                      error={tempParaDict["delta"] && tempParaDict["delta"].split(",").some((x) => isNaN(x))}
                      helperText={tempParaDict["delta"] && tempParaDict["delta"].split(",").some((x) => isNaN(x)) ? "Please enter a comma-separated list of numbers." : ""}
                      sx={{ mb: 2 }}
                    />
                  )}
                  {selectedModels.includes("Lassosum2") && (
                    <TextField
                      variant="outlined"
                      label="nlambda"
                      fullWidth
                      defaultValue={tempParaDict["nlambda"] || "30"}
                      onChange={(e) => setTempParaDict({ ...tempParaDict, nlambda: e.target.value })}
                      error={tempParaDict["nlambda"] && isNaN(tempParaDict["nlambda"])}
                      helperText={tempParaDict["nlambda"] && isNaN(tempParaDict["nlambda"]) ? "Please enter a number." : ""}
                      sx={{ mb: 2 }}
                    />
                  )}
                  {selectedModels.includes("Lassosum2") && (
                    <TextField
                      variant="outlined"
                      label="lambda min ratio"
                      fullWidth
                      defaultValue={tempParaDict["lambda_min_ratio"] || "0.01"}
                      onChange={(e) => setTempParaDict({ ...tempParaDict, lambda_min_ratio: e.target.value })}
                      error={tempParaDict["lambda_min_ratio"] && isNaN(tempParaDict["lambda_min_ratio"])}
                      helperText={tempParaDict["lambda_min_ratio"] && isNaN(tempParaDict["lambda_min_ratio"]) ? "Please enter a number." : ""}
                      sx={{ mb: 2 }}
                    />
                  )}

                  {selectedModels.includes("LDpred2") && (
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      LDpred2
                    </Typography>
                  )}
                  {selectedModels.includes("LDpred2") && (
                    <TextField
                      variant="outlined"
                      label="alpha"
                      fullWidth
                      defaultValue={tempParaDict["alpha"] || "0.7,1.0,1.4"}
                      onChange={(e) => setTempParaDict({ ...tempParaDict, alpha: e.target.value })}
                      error={tempParaDict["alpha"] && tempParaDict["alpha"].split(",").some((x) => isNaN(x))}
                      helperText={tempParaDict["alpha"] && tempParaDict["alpha"].split(",").some((x) => isNaN(x)) ? "Please enter a comma-separated list of numbers." : ""}
                      sx={{ mb: 2 }}
                    />
                  )}
                  {selectedModels.includes("LDpred2") && (
                    <TextField
                      variant="outlined"
                      label="P-seq"
                      fullWidth
                      defaultValue={tempParaDict["p_seq"] || "1e-05,3.2e-05,0.0001,0.00032,0.001,0.0032,0.01,0.032,0.1,0.32,1.0"}
                      onChange={(e) => setTempParaDict({ ...tempParaDict, p_seq: e.target.value })}
                      error={tempParaDict["p_seq"] && tempParaDict["p_seq"].split(",").some((x) => isNaN(x))}
                      helperText={tempParaDict["p_seq"] && tempParaDict["p_seq"].split(",").some((x) => isNaN(x)) ? "Please enter a comma-separated list of numbers." : ""}
                      sx={{ mb: 2 }}
                    />
                  )}
                  {selectedModels.includes("LDpred2") && (
                    <TextField
                      variant="outlined"
                      label="Sparse"
                      fullWidth
                      defaultValue={tempParaDict["sparse"] || "FALSE"}
                      sx={{ mb: 2 }}
                    />

                    // <FormControl component="fieldset" sx={{ flexDirection: "row" }}>
                    //   <Typography variant="body2" sx={{ m: 2 }}>
                    //     Sparse:
                    //   </Typography>
                    //   <RadioGroup
                    //     row
                    //     aria-label="sparse"
                    //     name="row-radio-buttons-group"
                    //     defaultValue={tempParaDict["sparse"] || "false"}
                    //     onChange={(e) => setTempParaDict({ ...tempParaDict, sparse: e.target.value })}
                    //   >
                    //     <FormControlLabel value="true" control={<Radio />} label="True" />
                    //     <FormControlLabel value="false" control={<Radio />} label="False" />
                    //     <FormControlLabel value="true, false" control={<Radio />} label="Both" />
                    //   </RadioGroup>
                    // </FormControl>


                  )}
                </Box>
              )}
            </Grid>
          )}
          {!selectedModels.includes("PRSCS") && (
            <Grid item xs={4} sx={gridItemStyle}>
              <StatusBox
                message="You can edit parameters for each method. Otherwise, we will run with default parameters."
                status="optional"
              />
            </Grid>
          )}

          {/* Save and Continue */}
          <Grid item xs={1}></Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              onClick={() => handleBack()}
              sx={{
                color: "white",
                backgroundColor: "grey",
                mb: 2,
                textTransform: "none",
                fontSize: 18,
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#0d47a1" },
              }}
            >
              Back to Previous Step
            </Button>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              onClick={() => handleSubmit()}
              sx={{
                color: "white",
                backgroundColor: "grey",
                mb: 2,
                textTransform: "none",
                fontSize: 18,
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#0d47a1" },
              }}
            >
              Save and Continue
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
