import React from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from '@mui/material';

const updates = [
  {
    date: "2024-11-19",
    description: (
      <>
        The <Link href="https://pennprs.gitbook.io/pennprs" target="_blank">tutorial</Link> is available for new users.
      </>
    ),
  },
  {
    date: "2024-10-13",
    description: "PennPRS is now live!",
  },
  {
    date: "2024-09-25",
    description: "Internal testing phase completed.",
  },
  {
    date: "2024-05-15",
    description: "Demo version of PennPRS is available.",
  },
];

export const UpdatesPage = () => {
  return (
    <Container sx={{ mt: 10, maxWidth: '800px' }}>
      <Typography 
        variant="h4" 
        component="h1" 
        gutterBottom 
        sx={{ fontWeight: 'bold', textAlign: 'center' }}
      >
        Updates
      </Typography>
      <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', textAlign: 'center' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', textAlign: 'center' }}>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updates.map((update, index) => (
              <TableRow key={index}>
                <TableCell sx={{ textAlign: 'center', fontSize: '0.9rem' }}>{update.date}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: '0.9rem' }}>{update.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
