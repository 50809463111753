import React, { useState, useEffect } from "react";

export const ResultPage = () => {
  const [activeTab, setActiveTab] = useState("GWAS Catalog");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const rowsPerPage = 10;
  const maxPageNumbersToShow = 5;

  const dataSources = {
    "GWAS Catalog": "/results_meta_data.json",
    MVP: "/results_meta_data.json",
    "Biobank Japan": "/japan_meta_data.json",
    GBMI: "/results_meta_data.json",
  };  

  useEffect(() => {
    setLoading(true);
    setError(null);

    fetch(dataSources[activeTab])
      .then((response) => response.json())
      .then((data) => {
        setTableData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading table data:", error);
        setError(error.message);
        setLoading(false);
      });
  }, [activeTab]);

  const filteredData = tableData.filter((row) =>
    row.pubmedId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.studyId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.diseaseTrait?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.ancestry?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.traitType?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleClick = (pageNumber) => setCurrentPage(pageNumber);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
  const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

  return (
    <div style={{ padding: '20px', marginTop: "56px" }}>
      <h1>Public Results</h1>

      {/* Tabs */}
      <div style={{ display: 'flex', marginBottom: '20px', borderBottom: '2px solid #ddd', justifyContent: 'center' }}>
        {Object.keys(dataSources).map((tab) => (
          <button
            key={tab}
            onClick={() => {
              setActiveTab(tab);
              setSearchTerm("");
              setCurrentPage(1);
            }}
            style={{
              padding: '10px 20px',
              cursor: 'pointer',
              border: 'none',
              borderBottom: activeTab === tab ? '3px solid #007bff' : 'none',
              backgroundColor: 'transparent',
              fontWeight: activeTab === tab ? 'bold' : 'normal',
              fontSize: '16px',
              color: activeTab === tab ? '#007bff' : '#333',
              marginRight: '10px',
            }}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Search Bar */}
      <input
        type="text"
        placeholder={`Search in ${activeTab}`}
        value={searchTerm}
        onChange={handleSearchChange}
        style={{
          width: '100%',
          padding: '12px 20px',
          margin: '8px 0',
          boxSizing: 'border-box',
          fontSize: '16px'
        }}
      />

      {/* Table */}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <table style={{
          width: '100%',
          borderCollapse: 'collapse',
          margin: '20px 0',
          fontSize: '14px',
          textAlign: 'left',
          fontFamily: '"Arial", sans-serif',
          color: '#333',
          border: '1px solid #999',
        }}>
          <thead>
            <tr>
              <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>PMID</th>
              <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>Study Accession</th>
              <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>Disease/Trait</th>
              <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>Ancestry</th>
              <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>Sample Size</th>
              <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>Trait Type</th>
              <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>Download Result</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.length > 0 ? (
              currentRows.map((row, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
                  <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>{row.pubmedId}</td>
                  <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>
                    <a href={`https://www.ebi.ac.uk/gwas/studies/${row.studyId}`} target="_blank" rel="noopener noreferrer" style={{ color: '#0056b3', textDecoration: 'underline' }}>{row.studyId}</a>
                  </td>
                  <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>{row.diseaseTrait}</td>
                  <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>{row.ancestry}</td>
                  <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>{row.sampleSize}</td>
                  <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>{row.traitType}</td>
                  <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>
                    <a href={row.downloadLink} target="_blank" rel="noopener noreferrer" style={{ color: '#0056b3', textDecoration: 'underline' }}>Download</a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" style={{ textAlign: 'center', padding: '20px' }}>
                  No results found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {/* Pagination */}
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          style={{
            margin: '0 5px',
            padding: '5px 10px',
            backgroundColor: currentPage === 1 ? '#ccc' : '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '3px',
            cursor: currentPage === 1 ? 'not-allowed' : 'pointer'
          }}
        >
          Previous
        </button>

        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handleClick(pageNumber)}
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              backgroundColor: currentPage === pageNumber ? '#007bff' : '#f1f1f1',
              color: currentPage === pageNumber ? 'white' : 'black',
              border: 'none',
              borderRadius: '3px',
              cursor: 'pointer'
            }}
          >
            {pageNumber}
          </button>
        ))}

        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          style={{
            margin: '0 5px',
            padding: '5px 10px',
            backgroundColor: currentPage === totalPages ? '#ccc' : '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '3px',
            cursor: currentPage === totalPages ? 'not-allowed' : 'pointer'
          }}
        >
          Next
        </button>
      </div>
      

      {/* Current Page Info */}
      <p style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', color: '#555' }}>
        Page {currentPage} of {totalPages}
      </p>
    </div>
  );
};
