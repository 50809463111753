import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import apis from "../../../api";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const IntroductionBlock = ({ setStep }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: 5,
        mb: 5,
      }}
    >
      <Box
        sx={{
          width: "95%",
          height: "70vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
          padding: "10px",
        }}
      >
         <img src="multi_intro.jpg" alt="intro" style={{ width: "80%", height: "auto", radius: "5px" }} />
      </Box>
      <Button
        variant="contained"
        type="submit"
        onClick={() => setStep(1)}
        sx={{
          color: "white",
          backgroundColor: "grey",
          mb: 2,
          mt: 2,
          fontSize: 18,
          fontWeight: 'bold',
          width: 200,
          "&:hover": { backgroundColor: "#0d47a1" },
        }}
      >
        Start
      </Button>
    </Box>
  );
};
