import React from "react";
import { Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const FAQPage = () => {
  const faqs = [
    {
      question: "What is this platform about?",
      answer:
        "This platform provides tools and resources for biomedical prediction and analysis, including Polygenic Risk Score (PRS) analysis.",
    },
    {
      question: "How do I get started?",
      answer:
        "To get started, sign up for an account, explore the tutorials, and upload your data for analysis.",
    }
  ];

  return (
    <Container maxWidth="md" sx={{ py: 6, mt: 6, mb: 2}}>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{ fontWeight: 600, textAlign: "center" }}
        >
          Frequently Asked Questions
        </Typography>         
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ textAlign: "center", maxWidth: "80%", margin: "0 auto" }}
        >
           If you have any questions, please check the FAQs below. If you can't find the answer you're looking for, please contact us in <a href="https://groups.google.com/g/pennprs" target="_blank" rel="noreferrer">here</a>.
        </Typography>
      </Box>
      {faqs.map((faq, index) => (
        <Accordion key={index} elevation={0} sx={{ border: "1px solid #ddd", borderRadius: 2, mb: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" color="textSecondary">
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};